// -----------------------------------------------------------------------------
// This file contains styles that are specific to the eyecare main/index.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$x}
--------------------------------------------------------------*/
.eyecare {
  .page-link {
    position: relative;
    width: 100%;

    .image {
      display: block;
      width: 100%;
      max-height: 500px;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      /* Black see-through */
      // color: $white;
      color: $g-optica-primary-color;
      width: 100%;
      transition: 0.5s ease;
      opacity: 1;
      font-size: 20px;
      font-weight: 600;
      padding: 20px;
      text-align: center;
    }
  }
}

.SpectacleLenses {
  .card-title {
    color: $g-optica-primary-color;
    font-weight: 450;
  }
}