// -----------------------------------------------------------------------------
// This file contains all owl-carousel override styles.
// -----------------------------------------------------------------------------

/**
* Background Image PlaceHolder Main Container
*/
.ph-item {
  position: relative;
  overflow: hidden;
  background-color: #ced4da;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation .8s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%,
            rgba(255, 255, 255, 0.35) 50%,
            rgba(255, 255, 255, 0) 54%) 50% 50%;
  }
}



@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}



.default-height {
  // by default ph-item has overflow:hidden property
    height: 506px;
}

// This class always should be parent
.slider-fouc {
  display: none;
}



.bg-image {
// background: no-repeat center center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  height: 506px;
}

.one {
  background-image: url(../images/main-slider/1.jpg);
}

.two {
  background-image: url(../images/main-slider/1.jpg);
}

.three {
  background-image: url(../images/main-slider/1.jpg);
}


// Every Set piece duration is 4s and it apply to all inside elements
.owl-item {
  .slide-animation {
    * {
      animation-duration: 1s;
      animation-fill-mode: both;
    }
  }
}
// Slide 1
.slide-content {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  h1 {
    font-size: 92px;
    margin-bottom: 0;
    color: $g-white;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
  }
  h2 {
    color: $g-white;
    font-size: 20px;
    margin-bottom: 0;
    font-family: 'Raleway', sans-serif;
  }
}

// Slide 2
.slide-content-2 {
  position: absolute;
  top: 50%;
  width: 448px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  .slide-2-h2-a {
    color: #0e4cb3;
    // default line-height = 1.2, 12px
  }
  .slide-2-h2-b {
    color: #dd1424;
    line-height: 54px;
  }
  .slide-2-h2-a, .slide-2-h2-b  {
    padding-left: 50px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    font-size: 68px;
    font-weight: bold;
  }
  h1 {
    margin-bottom: 0;
    color: $g-white;
    font-family: 'Open Sans', sans-serif;
    font-size: 195px;
    line-height: 0.91;// font-size * 0.91
    font-weight: bold;
  }
}


  // Slide 1,2,3 Animation
.owl-item.active {
  .slide-content {
    h1 {
      animation-delay: 0.5s;
      animation-name: bounceIn;
    }

    h2 {
      animation-delay: 1s;
      animation-name: fadeInUp;
    }
  }
  .slide-content-2 {
    .slide-2-h2-a {
      animation-delay: 0.5s;
      animation-name: fadeInLeft;
    }
    .slide-2-h2-b {
      animation-delay: 1s;
      animation-name: fadeInLeft;
    }

    h1 {
      animation-delay: 1.5s;
      animation-name: fadeInRight;
    }

    a {
      animation-delay: 2s;
      animation-name: fadeInRight;
    }
  }
}







/* Main-Slider (Previous & Next Buttons) */

.slider-main {
  &:hover {
    .main-slider-previous,.main-slider-next {
      opacity: 1;
    }
  }
}

.main-slider-previous, .main-slider-next {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  text-align: center;
  z-index: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 48px;
  font-size: 14px;
  background-color: $g-white;
  margin: auto 0;
  transition: opacity .6s ease-in;
  opacity: 0;
  i {
    color: $g-brand-secondary-color;
  }
}
.main-slider-previous {
  left:15px;
}
.main-slider-next {
  right:15px;
}




/* Products-Slider (Equal columns height fix) */
.products-slider.owl-carousel {
  .owl-stage {
    display: flex;
  }
  .owl-item {
    display: flex;
  }
}



/**
 * Products-Slider (Equal columns height fix)
 * These styles are only apply on the IE10 & IE11.
 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .products-slider.owl-carousel {
    .owl-stage {
      display: block !important;
      .owl-item {
        display: block !important;
      }
    }
  }
}













/* Products-Slider (Previous & Next Buttons) */
.products-slider {
  &:hover {
    .product-slider-previous,.product-slider-next {
      opacity: 1;
    }
  }
}

.product-slider-previous,.product-slider-next {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  background-color: $g-white;
  cursor: pointer;
  margin: auto 0;
  transition: opacity .6s ease-in;
  opacity: 0;
  i {
    color: $g-brand-secondary-color;
  }
}

.product-slider-previous {
  left: 0;
}

.product-slider-next {
  right: 0;
}



/* Specific-Category-Slider (Previous & Next Buttons) */
.specific-category-slider {
  &:hover {
    .specific-category-slider-previous,.specific-category-slider-next {
      opacity: 1;
    }
  }
}

.specific-category-slider-previous,.specific-category-slider-next {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  text-align: center;
  z-index: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 48px;
  font-size: 14px;
  background-color: $g-white;
  margin: auto 0;
  transition: opacity .6s ease-in;
  opacity: 0;
  i {
    color: $g-brand-secondary-color;
  }
}

.specific-category-slider-previous{
  left: 15px;
}


.specific-category-slider-next {
  right: 15px;
}


/* Brand-Slider (Previous & Next Buttons) */
.brand-slider-previous, .brand-slider-next {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  opacity: 0;
  transition: opacity .6s ease-in;
  i {
    color: #9e9e9e;
  }
}

.brand-slider-previous {
  left: 0;
}

.brand-slider-next {
  right: 0;
}

.brand-slider-content {
  &:hover {
    .brand-slider-previous,.brand-slider-next {
      opacity: 1;
    }
  }
}
