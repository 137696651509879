// -----------------------------------------------------------------------------
// This file contains our layout Typography styles.
// -----------------------------------------------------------------------------

/*--------------------------------------------------------------
#{$b}
--------------------------------------------------------------*/
/**
 * Basic typography style for copy text
 */
body {
  color: $g-text-color;
  font-family: $g-text-font-stack;
}
