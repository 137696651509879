// -----------------------------------------------------------------------------
// This file contains only header section comment for resultant CSS file.
// -----------------------------------------------------------------------------

/*
Theme Name: Groover
Author: Ahmad Hussnain
Description: Groover is modern, clean and polish multipurpose Ecommerce HTML
Template built with modern workflow tool like Webpack. When developing this
template we highly focused on some goals i.e. responsiveness, well tested on all
compatible browsers, designed to be reusable and could be easy to customizable
into any stores like clothing, toys, mobiles & tablets, electronics, books,
furniture etc. Features multiple sections on the front page as well as
navigation, widgets, banners slideshow, a logo, and more.
Version: 1.0.0
Tags: one-column, two-columns, three-columns, four-columns, right-sidebar,
custom-colors, custom-header, custom-menu, custom-logo, featured-images
Text Domain: Groover
*/

$a: '1.0 Base Styles';
$b: '2.0 Typography';
$c: '3.0 Pre-configured styles';
$d: '4.0 Local Layout Utility Styles';
$e: '5.0 Vendor Extensions';
$f: '6.0 Components';
$g: '7.0 Header';
$g-1: '7.1 Top-Header';
$g-2: '7.2 Mid-Header';
$g-3: '7.3 Responsive-Search';
$g-4: '7.4 Responsive-Buttons';
$g-5: '7.5 Mini-Cart';
$g-6: '7.6 Bottom-Header';
$h: '8.0 Footer';
$h-1: '8.1 Outer-Footer';
$h-2: '8.2 Mid-Footer';
$h-3: '8.3 Bottom-Footer';
$i: '9.0 Home-Page:';
$i-1: '9.1 Newsletter-Modal';
$i-2: '9.2 Banner-Layer';
$i-3: '9.3 Error-Not-Product';
$i-4: '9.4 Brand-Slider';
$i-5: '9.5 Site-Priorities';
$j: '10.0 About-Page';
$k: '11.0 Contact-Page';
$l: '12.0 FAQ-Page';
$m: '13.0 Store-Directory-Page';
$n: '14.0 Terms-&-Conditions-Page';
$o: '15.0 404-page';
$p: '16.0 Single-Product-Fullwidth-page:';
$p-1: '16.1 Product-zoom-area';
$p-2: '16.2 Product-details';
$p-3: '16.3 Detail-Tabs';
$p-4: '16.4 Description-Tab';
$p-5: '16.5 Specifications-Tab';
$p-6: '16.6 Reviews-Tab';
$p-7: '16.7 Detail-Different-Product-Section';
$q: '17.0 Blog-page';
$r: '18.0 Blog-Detail-page';
$s: '19.0 Cart-Wishlist-Page';
$t: '20.0 Checkout-And-Confirmation-Page';
$u: '21.0 Account-And-Lost-Password';
$v: '22.0 Track-Order';
$w: '23.0 Shop:';
$w-1: '23.1 Extract-Categories';
$w-2: '23.2 Page-Bar-Options';
$w-3: '23.3 Shop-Grid-View';
$w-4: '23.4 Shop-List-View';
$w-5: '23.5 Shop-Pagination';
$w-6: '23.6 Shop-Not-Found';
$w-7: '23.7 Shop-Search-Result';
$x: '24.0 Custom-Deal-Page';
$y: '25.0 Pages-Responsiveness';











/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
#{$a}
#{$b}
#{$c}
#{$e}
#{$f}
#{$g}
  #{$g-1}
  #{$g-2}
  #{$g-3}
  #{$g-4}
  #{$g-5}
  #{$g-6}
#{$h}
  #{$h-1}
  #{$h-2}
  #{$h-3}
#{$i}
  #{$i-1}
  #{$i-2}
  #{$i-3}
  #{$i-4}
  #{$i-5}
#{$j}
#{$k}
#{$l}
#{$m}
#{$n}
#{$o}
#{$p}
  #{$p-1}
  #{$p-2}
  #{$p-3}
  #{$p-4}
  #{$p-5}
  #{$p-6}
  #{$p-7}
#{$q}
#{$r}
#{$s}
#{$t}
#{$u}
#{$v}
#{$w}
  #{$w-1}
  #{$w-2}
  #{$w-3}
  #{$w-4}
--------------------------------------------------------------*/
