// -----------------------------------------------------------------------------
// This file contains styles that are specific to the account page.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$u}
--------------------------------------------------------------*/

.page-account, .page-lostpassword {
  .account-h2 {
    font-size: 35px;
  }

  .account-h6 {
    font-size: 13px;
    color: $g-text-color;
  }

  // apply to all label elements inside login accordion content
  label {
    font-weight: 600;
    font-size: 14px;
    color: $g-brand-secondary-color;
  }


  // Check box text
  .label-text {
    font-size: 13px;
    font-weight: normal;
  }

  // Page specific anchor
  .page-anchor {
    > a {
      font-size: 13px;
      display: inline-block;
    }
  }
}

.login-wrapper,.reg-wrapper {
  max-width: 480px;
  margin: auto;
}


.login-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: -1px;
  height: 100%;
}
