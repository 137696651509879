// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
@import "~bootstrap/scss/bootstrap";

/* #{$h-1} */

/* #{$h-2} */
.mid-footer-wrapper {
  border-bottom: 1px solid $g-optica-primary-color;
}

.footer-list {
  h6 {
    font-weight: 600;
    color: $g-optica-primary-color;
  }

  p {
    color: $g-white;
    font-size: 14px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;

    >li {
      color: $g-white;

      >a {
        font-size: 13px;
        text-decoration: none;
        color: $g-white;

        &:hover {
          color: $g-white;
        }
      }
    }
  }
}

.footer {
  .address {
    li {
      padding: 5px;
    }

    a {
      text-decoration: none;
      color: $white;

      &:hover {
        color: $g-optica-primary-color;
      }
    }
  }
}

.footer-list-content-alignment {
  display: inline-block;
  text-align: left;

  img{
    width: 350px;
    margin-top: 10px;
    border-radius: 25px;
  }
}

.footer-list-alignment {
  text-align: center;
}

@include media-breakpoint-down(md) {
  .footer-list-alignment {
    text-align: left;
  }
}

/* #{$h-3} */
.bottom-footer-wrapper {
  text-align: center;
  padding: 20px 0;

  .copyright-text {
    color: $g-optica-primary-color;
    font-size: 13px;
    margin: 0;

    a {
      color: $g-brand-color;
      text-decoration: none;

      &:hover {
        color: $g-white;
      }
    }
  }
}

.social-media-wrapper {
  margin-bottom: 6px;

  i {
    color: $g-optica-primary-color;
  }
}