// -----------------------------------------------------------------------------
// This file contains styles that are specific to the 404 page.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$o}
--------------------------------------------------------------*/

.page-404 {

  h1 {
    font-size: 200px;
    color: #333;
    font-weight: 700;
  }

  h5 {
    font-size: 13px;
    color: #333;
  }
}
