// -----------------------------------------------------------------------------
// This file contains styles that are specific to the deal page.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$x}
--------------------------------------------------------------*/

.deal-page-wrapper {
  text-align: center;
  margin-bottom: 56px;
  > .deal-heading {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  > .deal-has-total-items {
    font-size: 13px;
    margin-bottom: 4px;
    color: $g-text-color;
  }
}
