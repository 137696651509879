/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
// Bootstrap framework from `node_modules`
@import "~bootstrap/scss/bootstrap";

.page-style-optica  {
    
    background: url(../images/pattern/pattern-d.png) repeat center center;
    &:after {
        background: $g-black;
       
      }

    padding-top: 100px;
    padding-bottom: 100px;
    ;

    h1,
    h2,
    h3,
    h4 {
        color: $g-optica-primary-color;
        padding-bottom: 25px;
    }
}

.home-button {
    background-color: $g-optica-primary-color;
    color: $g-black;
    font-weight: bold;
    padding: 10;
    margin-top: 10px;
}

.flex-equal>* {
    flex: 1;
}

@media (min-width: 768px) {
    .flex-md-equal>* {
        flex: 1;
    }
}

//   from the page


/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
    padding-bottom: 30px;

}

.why-us .card {
    border-radius: 3px;
    border: 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.why-us .card-icon {
    text-align: center;
    margin-top: -32px;
}

.why-us .card-icon i {
    font-size: 32px;
    color: #fff;
    width: 64px;
    height: 64px;
    padding-top: 10px;
    text-align: center;
    background-color: $g-optica-primary-color;
    border-radius: 50%;
    text-align: center;
    border: 4px solid #fff;
    transition: 0.3s;
    display: inline-block;
}

.why-us .card-body {
    padding-top: 12px;
}

.why-us .card-title {
    font-weight: 700;
    text-align: center;
}

.why-us .card-title a {
    color: #743e1d;
}

.why-us .card-title a:hover {
    color: #fd680e;
}

.why-us .card-text {
    color: #5e5e5e;
}

.why-us .card:hover .card-icon i {
    background: #fff;
    color: $g-optica-primary-color;
}

@media (max-width: 1024px) {
    .why-us {
        background-attachment: scroll;
    }
}

#whatWeDo {
    padding-top: 15px;
    padding-bottom: 15px;;
    h3 {
        color: $g-optica-primary-color;
    }
}