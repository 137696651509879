/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section-main {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg,
.services .icon-box {
    background-color: #f8f9fa;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: $g-optica-primary-color;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #556270;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    .content h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $g-optica-primary-color;
    }

    .content h3 {
        font-weight: 500;
        line-height: 32px;
        font-size: 24px;
    }

    .content ul {
        list-style: none;
        padding: 0;
    }

    .content ul li {
        padding: 10px 0 0 28px;
        position: relative;
    }

    .content ul i {
        left: 0;
        top: 7px;
        position: absolute;
        font-size: 20px;
        color: #d9232d;
    }

    .content p:last-child {
        margin-bottom: 0;
    }
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team {

    .member {
        position: relative;
        box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
        padding: 30px;
        border-radius: 4px;
        background: white;

        .pic {
            overflow: hidden;
            width: 140px;
            border-radius: 4px;
            min-width: 140px;
        }

        .pic img {
            transition: ease-in-out 0.3s;
        }

        :hover img {
            transform: scale(1.1);
        }

        .member-info {
            padding-left: 30px;
        }

        h4 {
            font-weight: 700;
            margin-bottom: 5px;
            font-size: 20px;
            color: #556270;
        }

        span {
            display: block;
            font-size: 15px;
            padding-bottom: 10px;
            position: relative;
            font-weight: 500;
        }

        span::after {
            content: "";
            position: absolute;
            display: block;
            width: 50px;
            height: 1px;
            background: #dee2e6;
            bottom: 0;
            left: 0;
        }

        p {
            margin: 10px 0 0 0;
            font-size: 14px;
        }

        .social {
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .social a {
            transition: ease-in-out 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 32px;
            height: 32px;
            background: #8795a4;
            text-decoration: none;
        }

        .social a i {
            color: #fff;
            font-size: 16px;
            margin: 0 2px;
        }

        .social a:hover {
            background: $g-optica-primary-color; //#d9232d;
        }

        .social a+a {
            margin-left: 8px;
        }
    }
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills {
    .section-title {
        p{
            font-weight: 600;
            font-family: "Poppins", sans-serif;
    
        }
        .frame-selection {

            font-size: 24px;
            text-transform: uppercase;
            // color: #556270;
            color: $g-optica-primary-color;

        }
        .pop-in {

            font-size: 22px;
            text-transform: uppercase;
            color: $g-optica-secondary-color;

            // color: $g-optica-primary-color;

        }
    }
}