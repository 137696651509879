// -----------------------------------------------------------------------------
// This file contains styles that are specific to the contact page.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$k}
--------------------------------------------------------------*/
.contact-h1 {
  border-bottom: 1px solid $g-border-color;
  color: $g-brand-secondary-color;
  font-size: 22px;
  padding-bottom: 14px;
  margin-bottom: 26px;
  position: relative;

  &:after {
    content: " ";
    border-bottom: 2px solid $g-optica-primary-color;
    display: block;
    width: 14%;
    position: absolute;
    bottom: -1px;
  }
}

.touch-wrapper {
  form {
    label {
      font-weight: 600;
      font-size: 14px;
      color: $g-brand-secondary-color;
    }

    textarea {
      height: 175px;
    }
  }

  #getInTouch-storeName {
    color: $g-optica-primary-color;
  }
}

.information-about-wrapper {
  p {
    font-size: 13px;
    margin-bottom: 30px;
  }
}

.contact-material {
  h6 {
    color: $g-text-color;
    font-weight: 600;
    font-size: 14px;
  }

  span {
    display: block;
    font-size: 13px;
  }
}

#map {
  height: 375px;
}

////
///
.optica-modal {
  .modal-header {
    border: none;
    padding: 10px;
  }

  .modal-body {
    padding-top: 0;
  }
}

.optica-modal-content {
  h6 {
    font-weight: 600;
    color: $g-optica-primary-color;
  }

  p {
    color: $g-white;
    font-size: 14px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;

    li {
      color: $g-black;
      padding: 5px;

      a {
        font-size: 13px;
        text-decoration: none;
        color: $g-black;
      }
    }
  }


  // li {
  //   padding: 5px;
  // }
  // a {
  //   text-decoration: none;
  //   color: $g-black;
  // }

}


.form-input {
  padding-top: 20px;
}

//// Old for google map

#googleMap {
  height: 100%;
  min-height: 400px;
  /*float: right;*/
}

.marker {
  font-size: 12px;
  color: darkorange;
}

.marker-text {
  position: relative;
  left: -50%;
  top: -5px;
  white-space: nowrap;
  border: 1px solid blue;
  padding: 2px;
  background-color: orange;
  z-index: 100;
  font-size: 15px;
  font-weight: bold;
}

.modal-content {
  -ms-border-radius: 0;
  border-radius: 0;
}

/*  practices images */
.page-contact {

  /* button group*/
  .btn-group .btn {
    margin-bottom: 0.5rem;
    background-color: $g-optica-default-button-group-color;
  }

  .btn-group .btn-check:checked+.btn {
    background-color: $g-optica-primary-color;
    color: black;
  }

  /* Custom styling for button group to appear as separate buttons */
  .btn-group .btn {
    margin-right: 5px;
    /* Adds space between buttons */
    border-radius: 0.25rem !important;
  }


  /*End button group*/

  ul.images {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    list-style: none;
  }

  ul.images li {
    flex: 0 0 auto;
  }

  ul.images li img {
    width: 100px;
    height: 100px;
    padding: 5px;
    border-radius: 10px;
  }

  #storeDetails {

    h6 {
      font-weight: 600;
      color: $g-optica-primary-color;
    }

    p {
      color: $g-white;
      font-size: 14px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      >li {

        text-align: left;
        color: $g-optica-primary-color;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

}