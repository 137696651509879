// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

/*--------------------------------------------------------------
#{$g}
--------------------------------------------------------------*/

/* #{$g-1} */
.full-layer-outer-header {
  background-color: $g-white;
}

.full-layer-nhs-header {
  background-color: $g-white;
  border-bottom: 1px solid $g-border-color;
  padding-bottom: 10px;
}

.primary-nav {
  float: left;
}

.secondary-nav {
  float: right;

  >li {
    >a {
      font-size: 15px;
      text-decoration: none;
    }
  }
}

/* #{$g-2} */
.full-layer-mid-header {
  background-color: $g-white;
  padding: 10px 0 0;

  h6 {
    padding-top: 5px;
    margin: 0;
    text-transform: uppercase;
    font-size: .8em;
  }

  .row {
    position: relative;
  }
}

.brand-logo {
  a {
    display: inline-block;
    vertical-align: top;

    img {
      width: 250px;
    }
  }
}

.nhs-logo {
  
    img {
      width: 200px;
    }
    padding-top: 5px;
}

.private-home-button {
  display: none;
  .private-home-text {
    border-radius: 25px;
    border: 2px solid goldenrod;
    padding: 0.6em;

    >a {
      font-size: 13px;
      font-weight: bold;
      color: goldenrod;
      text-decoration: none;
    }
  }
}

/* #{$g-4} */

.nav-item {
  a {
    color: rgba(239, 188, 5, 0.948);
    // color: goldenrod;
  }

  .dropdown-menu {
    background-color: rgb(33, 37, 41);
  }
}

/* #{$g-5} */

.full-layer-bottom-header {
  background-color: $g-white;

  .row {
    position: relative;
  }
}

/* #{$g-6} */