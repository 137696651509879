// -----------------------------------------------------------------------------
// This file contains styles that are specific to the appointment types.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$x}
--------------------------------------------------------------*/

.appointmentTypes, .help-and-advice {
  .accordion-header {
    button {
      font-weight: 500;
    }
  }
  .left-body {
    background-color: tint-color($g-optica-primary-color, 30%);
    height: 100%;
    position: relative;
    h5 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .right-body {
    background-color: tint-color($g-optica-primary-color, 80%);
  }
}
