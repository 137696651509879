// -----------------------------------------------------------------------------
// This file contains all Bootstrap override styles.
// -----------------------------------------------------------------------------
@import "~bootstrap/scss/bootstrap";

/* Modal styles */
.modal {
  z-index: 999999;
}

.modal-dialog {
  width: 100%;
  max-width: 980px;
}

.modal-content {
  display: block !important;
  border-radius: 0;
}

.modal-backdrop {
  z-index: 999998;
}

$g-optica-default-button-group-color: tint-color($g-optica-secondary-color, 85%);

$accordion-border-color: rgba($black, 0.25);
$accordion-button-bg: tint-color($g-optica-primary-color, 85%);
$accordion-button-active-bg: $g-optica-primary-color;
$accordion-button-focus-border-color: none; //  $input-focus-border-color;
$accordion-button-focus-box-shadow: none; // $btn-focus-box-shadow;
// $accordion-border-radius: 0;

$link-decoration: none;