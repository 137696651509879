// -----------------------------------------------------------------------------
// This file contains styles that are specific to the blog page.
// -----------------------------------------------------------------------------
/*--------------------------------------------------------------
#{$q}
--------------------------------------------------------------*/
/* Blog-Global-Styles */

// Info Section
.blog-post-info {
  font-size: 13px;
}

.blog-post-preposition {
  margin-right: 2px;
}


.blog-post-author-name {
  transition: all .3s ease-in-out;
  color: $g-brand-color;
  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
}

.blog-post-info-separator  {
  display: inline-block;
  margin: 0 4px;
}


.blog-post-published-date {
  span {
    margin: 0 4px;
  }
}

.blog-post-comment {
  color: $g-text-color;
  &:hover {
    color: $g-text-color;
    text-decoration: underline;
    opacity: 0.8;
  }
}

/* Blog-Posts */
// Specificity 1
.blog-post-wrapper {
  background-color: $g-black;
  overflow: hidden;
}

// Specificity 1
.blog-post-anchor {
  display: block;
  transition: all 1s ease-out;
  > img {
    display: block;
  }
}

// Specificity 3
.blog-post:hover {
  .blog-post-anchor {
    opacity: .6;
    transform: scale(1.1);
  }
}

// Specificity 2
.blog-post-heading {
  font-size: 20px;
  > a {
    transition: all .3s ease-in-out;
  }
}

.blog-post-paragraph {
  color: $g-brand-secondary-color;
  font-size: 14px;
  line-height: 2.5; // 40px
}

.blog-post-read-more {
  border-bottom: 1px solid #eceff8;
  color: $g-text-color;
  display: inline-block;
  font-size: 13px;
  padding-bottom: 2px;
  transition: .3s;
  &:hover {
    color: $g-text-color;
    border-color: $g-brand-secondary-color;
  }
}



/* Blog-Post-Pagination */
.blog-pagination {
  text-align: center;
}

.blog-pg-list {
  margin: 0;
  padding: 0;
  display: inline-block;
  > li {
    list-style: none;
    padding: 6px;
    display: inline-block;
  }
}

.bl-pg-link {
  font-size: 12px;
  color: $g-text-color;
  display: inline-block;
  transition: all .4s;
  border: 1px solid transparent;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.bl-pg-link-is-active,.bl-pg-link-hover-a:hover {
  border-color: $g-brand-secondary-color;
  color: $g-brand-secondary-color;
}

.bl-pg-link-hover-gap:hover {
  color: $g-text-color;
}


.bl-pg-link-hover-b:hover {
  color: $g-brand-secondary-color;
  text-decoration: underline;
}



/* Blog-Sidebar-Components */
.bl-sidebar-title-h3 {
  font-size: 14px;
  font-weight: 600;
  color: $g-brand-secondary-color;
  position: relative;
  &:after {
    content: ' ';
    border-bottom: 2px solid $g-brand-color;
    display: block;
    width: 12%;
    position: absolute;
    bottom: -7px;
  }
}

/* Blog-Sidebar-Search */
.group-text-blog-search {
  position: relative;
  overflow: hidden;


  .text-field {
    padding-right: 40px;
  }

  button {
    width: 40px;
    height: 46px;// because of text-field border
    position: absolute;// Relative to Form Component We Add it On A TextField
    right: 0;
    top: 50%;
    padding: 0;
    background-color: transparent;
    transform: translateY(-50%);
  }
}


.bl-sidebar-list {
  margin: 0;
  padding: 0;
  > li {
    list-style: none;
    padding-bottom: 20px;
    > a {
      font-size: 14px;
    }
  }
}



/* Blog-Sidebar-Recent-Post */
.recent-post-wrapper {
  display: table;
  width: 100%;
}

.recent-post-image {
  display: table-cell;
  vertical-align: middle;
  > a {
    display: inline-block;
    > img {
      width: 60px;
      height: 60px;
    }
  }
}


.recent-post-info {
  padding: 0 8px;
  font-size: 13px;
  display: table-cell;
  vertical-align: middle;
  > a {
    padding: 4px 0;
    display: block;
  }
  .recent-post-date {
    span {
      margin: 0 2px;
    }
  }
}

/* Blog-Sidebar-Tag */

.bl-tag {
  transition: .3s;
  font-size: 13px;
  display: inline-block;
  padding: 8px 14px;
  border: 1px solid #eceff8;
  margin-left: 4px;
  background-color: transparent;
  color: #626262;
  &:hover {
    background-color: $g-brand-color;
    color: $g-white;
    border-color: $g-brand-color;
  }
}
